@import url('https://fonts.googleapis.com/css?family=Montserrat');

h3 {
    margin: 10px 0;
}

h6 {
    margin: 5px 0;
}

p {
    font-size: 14px;
    line-height: 21px;
}

.card-container {
    border-radius: 5px;
    box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.75);
    color: #fff;
    padding-top: 30px;
    position: relative;
    width: 350px;
    max-width: 100%;
    text-align: center;
}

.card-container .pro {
    color: #fff;
    background-color: #FEBB0B;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    padding: 3px 7px;
    position: absolute;
    top: 30px;
    left: 30px;
}

.card-container .round {
    border: 1px solid #03BFCB;
    border-radius: 50%;
    padding: 7px;
}

button.primary {
    background-color: #03BFCB;
    border: 1px solid #03BFCB;
    border-radius: 3px;
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    padding: 10px 25px;
}

button.primary.ghost {
    background-color: transparent;
    color: #02899C;
}

.skills {
    text-align: left;
    padding: 15px;
    margin-top: 30px;
}

.skills ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.skills ul li {
    border-radius: 5px;
    display: inline-block;
    font-size: 12px;
    margin: 0 7px 7px 0;
    padding: 7px;
}
